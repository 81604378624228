import { render, staticRenderFns } from "./PromotionDialog.vue?vue&type=template&id=252bcf2c&scoped=true"
import script from "./PromotionDialog.vue?vue&type=script&lang=js"
export * from "./PromotionDialog.vue?vue&type=script&lang=js"
import style0 from "./PromotionDialog.vue?vue&type=style&index=0&id=252bcf2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252bcf2c",
  null
  
)

export default component.exports